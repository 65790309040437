import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, Container, Paper, TablePagination } from '@material-ui/core';
import { RadioButtonUnchecked, RadioButtonChecked } from '@material-ui/icons';

import { useGridStyles } from './styled/StudiesViewMakeStyles';
import { StudiesGridTable } from './StudiesGridTable';
import { SelectedItemComponent, SelectedMultipleItems } from '../Common/StudyCard/StudiesGrid';

const SelectedIcon = ({ selected }) => (selected ? <RadioButtonChecked /> : <RadioButtonUnchecked />);
const getElementHeight = (element) => element && element.getBoundingClientRect().height;

export const StudiesGridView = ({
  attributes,
  items,
  loading,
  dark,
  selected,
  handleSelected,
  handleAction,
  filterElement,
  rowsPerPage,
  setRowsPerPage,
  setCursor,
}) => {
  const { t } = useTranslation();
  const { fixSidebar } = useSelector((state) => state && state.userInterface);
  const [sentinel, setSentinel] = useState();
  const [page, setPage] = useState(0);
  const [totalStudies, setTotalStudies] = useState(0);
  const [pageReference, setPageReference] = useState({ 0: null });
  const [filterHeight, setFilterHeight] = useState(0);
  const styles = {
    offsetX: fixSidebar ? 285 : 0,
    offsetY: sentinel && sentinel.getBoundingClientRect().y,
    dark,
  };
  const classes = useGridStyles(styles);
  const updateFilterHeight = () => {
    const height = getElementHeight(filterElement);
    if (height && (height !== filterHeight)) setFilterHeight(height);
  };
  setTimeout(updateFilterHeight, 750);

  if (items && (items.totalCount !== totalStudies)) {
    setTotalStudies(items.totalCount);
    setPage(0);
  }

  const handleGoto = (index) => {
    const dicomStudy = items && items.edges && items.edges[index] && items.edges[index].node;
    handleAction(dicomStudy);
  };

  const handleChangePage = (event, newPage) => {
    if (loading) return;
    if (!items) return;

    if (newPage > page) {
      pageReference[newPage] = items.pageInfo.endCursor;
      setPageReference(pageReference);
    }
    setPage(newPage);
    setCursor(pageReference[newPage]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPageReference({ 0: null });
    setCursor(0);
  };

  const handleSelectedItem = (sel) => {
    setFilterHeight(filterHeight - 1);
    handleSelected(sel);
  };

  const labelDisplayedRows = ({ from, to, count }) => `${from}-${to} ${t('of')} ${count}`;
  const selectedItem = selected && selected.length === 1
    && items && items.edges && items.edges[selected[0]] && items.edges[selected[0]].node;

  return (
    <Container maxWidth="lg" ref={(i) => setSentinel(i)} className={classes.section}>
      <Container className={classes.selection}>
        {selected && selected.length === 1 && (
          <SelectedItemComponent
            item={selectedItem}
            sentinel={sentinel}
            handleClick={() => handleSelectedItem(selected[0])}
            dark={dark}
          />
        )}
        {selected && selected.length > 1 && (
          <SelectedMultipleItems
            length={selected.length}
            onClick={() => handleSelectedItem()}
          />
        )}
      </Container>
      <Container className={classes.main}>
        <Box className={classes.wrapper}>
          <Paper elevation={2} className={classes.box}>
            <StudiesGridTable
              attributes={attributes}
              items={items && items.edges && items.edges.map((edge) => edge.node)}
              loading={loading}
              selected={selected}
              handleSelected={handleSelectedItem}
              handleGoto={handleGoto}
              SelectedIcon={SelectedIcon}
              defaultRowCellStyle="cursor"
              dark={dark}
            />
          </Paper>
        </Box>
      </Container>
      <Container maxWidth="lg" className={classes.pagination}>
        <Box>
          {items && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={items.totalCount}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={t('rows.per.page')}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={labelDisplayedRows}
            />
          )}
        </Box>
      </Container>
    </Container>
  );
};
